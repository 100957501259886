// index.js

//common config



const isProduction = process.env.NODE_ENV === 'production';


//请求服务器的公共部分放到这个地方
module.exports = {
    apiUrl: isProduction ? 'https://api.example.com' : 'http://localhost:3000',
    // 其他全局配置项...
    platformUrl:   'https://platform.uaecntrade.com',
};


