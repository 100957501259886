<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/product/head-banner.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <div class="title">{{ this.$t('uaecn.page_contact.page_title') }}</div>
          <div class="text-center">
            {{ this.$t('uaecn.page_contact.page_title_sub') }}
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="separator separator-primary">
          <div class="section section-content"
               style=" background-size: cover; background-position: top center; min-height: 300px;">
            <div class="container">
              <div class="row">
                <div class="col-md-6 align-items-center" style="padding: 20px;">
                  <!-- Second image on the left side of the article -->
                  <div class="text-container">
                    <h3>
                      {{ this.$t('uaecn.footer.title') }}
                    </h3>
                    <div style="display: flex">
                      <div>
                        <p style="width: 100px;">
                          {{ this.$t('uaecn.page_contact.address0') }}
                        </p>
                      </div>
                      <div>
                        <p>
                          {{ this.$t('uaecn.page_contact.address1') }}
                        </p>
                        <p>
                          {{ this.$t('uaecn.page_contact.address2') }}
                        </p>
                      </div>
                    </div>
                    <p>
                      {{ this.$t('uaecn.footer.email') }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6" style="background-color: #F6F6F6;border-radius: 8px;padding: 20px;">
                  <!-- Second image on the left side of the article -->
                  <div class="row text-center align-items-center"
                       style="text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);align-items: center">
                    <div class="col-md-3">
                      <img class="rounded-circle" style="height:80px;width:80px;" src="img/product/connect-phone.png"/>
                    </div>
                    <div class="col-md-9 text-left align-items-center">
                      <div style="color: #666666;font-size: 20px;">
                        {{ this.$t('uaecn.page_contact.wt_t') }}
                      </div>
                      <div style="color: #2f5394;font-size: 20px;">
                        {{ this.$t('uaecn.page_contact.wt_m') }}
                      </div>
                      <div style="color: #666666;font-size: 16px;">
                        {{ this.$t('uaecn.page_contact.wt_e') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="img/product/connect-bottom-banner.png"/>
      </div>
    </div>
  </div>
</template>
<script>
import {Button, FormGroupInput} from '@/components';
import ContextBanner from './ContextBanner.vue';
import ContextImageText from './ContextImageText.vue';
import ContextTextImage from './ContextTextImage.vue';

export default {
  name: 'context',
  bodyClass: 'landing-page',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ContextBanner,
    // eslint-disable-next-line vue/no-unused-components
    ContextImageText,
    // eslint-disable-next-line vue/no-unused-components
    ContextTextImage,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {};
  }
};
</script>
<style scoped>

.section {
  padding: 25px 0 50px 0;
}

p {
  font-weight: 400 !important;
}

.title {
  color: #FFFFFF;
  font-size: 30px;
  text-align: center;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.text-center {
  color: #FFFFFF;
  font-size: 26px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.row {
  align-items: center;
}

.text-left {
  padding: 20px 20px 20px 0px;
}

@media screen and (max-width: 500px) {
  .text-left {
    padding: 20px;
    text-align: center !important;
  }
}
</style>
