<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/product/chanye-head-banner.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <div class="title">{{ this.$t('uaecn.page_service_inv.page_title') }}</div>
          <div class="text-center">
            {{ this.$t('uaecn.page_service_inv.page_title_sub') }}
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row justify-content-center">
          <!-- Tab 1 -->
          <a class="col-md-auto col-12 tab-item" href="#/serviceGSC"
             style="width: 250px;height:55px;color: #666666;line-height: 41px; margin-left: 10px;margin-right: 10px;">
            {{ this.$t('uaecn.header.menu_sub2_pol') }}
          </a>
          <!-- Tab 2 -->
          <a class="col-md-auto col-12 tab-item" href="#/serviceITSC"
             style="width: 250px;height:55px;color: #666666;line-height: 41px; margin-left: 10px;margin-right: 10px;">
            {{ this.$t('uaecn.header.menu_sub2_tra') }}
          </a>
          <!-- Tab 3 -->
          <a class="col-md-auto col-12 tab-item" href="#/serviceIIFSC"
             style="width: 250px;height:55px;color: #FFFFFF;line-height: 41px;  margin-left: 10px;margin-right: 10px;background-color: #2f5394;">
            {{ this.$t('uaecn.header.menu_sub2_inv') }}
          </a>
          <!-- Tab 4 -->
          <a class="col-md-auto col-12 tab-item" href="#/serviceIFSC"
             style="width: 250px;height:55px;color: #666666;line-height: 41px;  margin-left: 10px;margin-right: 10px;">
            {{ this.$t('uaecn.header.menu_sub2_pay') }}
          </a>
          <!-- Tab 5 -->
          <a class="col-md-auto col-12 tab-item" href="#/serviceICECC"
             style="width: 250px;height:55px;color: #666666;line-height: 41px; margin-left: 10px;margin-right: 10px;">
            {{ this.$t('uaecn.header.menu_sub2_cul') }}
          </a>
        </div>
        <!-- Tab内容 -->
        <div class="tab-content">
          <div v-show="currentTab === 3">
            <div class="container my-5">
              <!--     国际投融资服务中心   图文 开始   -->
              <div class="container">
                <div class="row">
                  <div class="col-md-12 ml-auto mr-auto">
                    <h3 style="text-align: center;">{{ this.$t('uaecn.page_service_inv.content_t1') }}</h3>
                    <p style="text-align: center;">
                      {{ this.$t('uaecn.page_service_inv.content_t1_p1') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="section">
                <div class="row">
                  <div class="col-md-3">
                    <div class="card card-padding" style="background-color:#2F5394;">
                      <img class="card-img-top" src="img/product/touzi-huanjing.png"/>
                      <H4 class="card-title card-title-margin">
                        {{ this.$t('uaecn.page_service_inv.card1_t') }}
                      </H4>
                      <div class="card-body  card-body-height" :lang="language">
                        <p>{{ this.$t('uaecn.page_service_inv.card1_c') }}</p>
                      </div>
<!--                      <div    style="text-align: center;margin-bottom: 20px;">-->
<!--                        <button class="btn  btn-info btn-lg button-style" type="button"  @click="openPlatform()" >-->
<!--                          {{ this.$t('uaecn.page_service_inv.button') }}-->
<!--                        </button>-->
<!--                      </div>-->
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="card card-padding" style="background-color:#2F5394;">
                      <img class="card-img-top" src="img/product/touzi-hezuo.png"/>
                      <H4 class="card-title card-title-margin">
                        {{ this.$t('uaecn.page_service_inv.card2_t') }}
                      </H4>
                      <div class="card-body card-body-height" :lang="language">
                        <p>{{ this.$t('uaecn.page_service_inv.card2_c') }}</p>
                      </div>
<!--                      <div    style="text-align: center;margin-bottom: 20px;">-->
<!--                        <button class="btn  btn-info btn-lg button-style" type="button"  @click="openPlatform()" >-->
<!--                          {{ this.$t('uaecn.page_service_inv.button') }}-->
<!--                        </button>-->
<!--                      </div>-->
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="card card-padding" style="background-color:#2F5394;">
                      <img class="card-img-top" src="img/product/rongzi-fuwu.png"/>
                      <H4 class="card-title card-title-margin">
                        {{ this.$t('uaecn.page_service_inv.card3_t') }}
                      </H4>
                      <div class="card-body card-body-height" :lang="language">
                        <p>{{ this.$t('uaecn.page_service_inv.card3_c') }}</p>
                      </div>
<!--                      <div   style="text-align: center;margin-bottom: 20px;">-->
<!--                        <button class="btn  btn-info btn-lg button-style" type="button"  @click="openPlatform()" >-->
<!--                          {{ this.$t('uaecn.page_service_inv.button') }}-->
<!--                        </button>-->
<!--                      </div>-->
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="card card-padding" style="background-color:#2F5394;">
                      <img class="card-img-top" src="img/product/zhengce-hezuo.png"/>
                      <H4 class="card-title  card-title-margin">
                        {{ this.$t('uaecn.page_service_inv.card4_t') }}
                      </H4>
                      <div class="card-body card-body-height" :lang="language">
                        <p>{{ this.$t('uaecn.page_service_inv.card4_c') }}</p>
                      </div>
<!--                      <div    style="text-align: center;margin-bottom: 20px;">-->
<!--                        <button class="btn  btn-info btn-lg button-style " type="button"   @click="openPlatform()" >-->
<!--                          {{ this.$t('uaecn.page_service_inv.button') }}-->
<!--                        </button>-->
<!--                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
                <div style="display: flex;justify-content: center">
                  <button class="btn  btn-primary btn-lg " type="button"  @click="openPlatform()"  >
                    {{ this.$t('uaecn.page_service_pol.button') }}
                  </button>
                </div>
              <!--     国际投融资服务中心   图文 结束   -->
            </div>
          </div>
          <div v-show="currentTab === 2">Content of Tab 2</div>
          <div v-show="currentTab === 4">Content of Tab 4</div>
          <div v-show="currentTab === 5">Content of Tab 5</div>
        </div>
      </div>
    </div>
    <!--    tabs  end-->
  </div>
</template>
<script>
import {Button, FormGroupInput} from '@/components';
import ContextBanner from './ContextBanner.vue';
import ContextImageText from './ContextImageText.vue';
import ContextTextImage from './ContextTextImage.vue';
import config from '@/config/index.js';

// 获取配置文件中的 URL
const platformUrl = config.platformUrl;


export default {
  name: 'context',
  bodyClass: 'landing-page',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ContextBanner,
    // eslint-disable-next-line vue/no-unused-components
    ContextImageText,
    // eslint-disable-next-line vue/no-unused-components
    ContextTextImage,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      language: 'zh',
      currentTab: 3,
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  },
  created() {
    let value = localStorage.getItem('change-language');
    if (value) {
      this.language = value
    }
  },
  methods: {
    openPlatform() {
      if (window.innerWidth <= 760) {
        this.$router.push('/login')
      }else {
        // 使用 window.open 打开新窗口
        window.open(platformUrl, '_blank');
      }
    }
  }
};
</script>
<style scoped>

.title {
  color: #FFFFFF;
  font-size: 30px;
  text-align: center;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.text-center {
  color: #FFFFFF;
  font-size: 26px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

p {
  font-size: 16px;
  font-weight: 400 !important;
}

.section {
  padding: 20px 0 !important;
}

.button-style {
  background-color: white !important;
  color: #2F5394 !important;
  border-radius: 40px !important;
}

.card-body-height[lang="zh"] {
  height: 105px;
  margin-bottom: 60px;
  padding-left: 30px !important;
  padding-right:30px !important;
}

.card-body-height[lang="en"] {
  height: 200px;
  margin-bottom: 70px;
  padding-left: 30px !important;
  padding-right:30px !important;
}

.card-body-height p{
  color: white !important;
  font-size:16px;
  font-weight: 400;
}


.card-title{
  margin-bottom: 0.75rem!important
}

.container-a-class {
  text-decoration: none;
  color: inherit;
  cursor: default;
}

/* 鼠标悬浮时显示可点击样式 */
a:hover {
  color: #FFFFFF !important;
  text-decoration: none !important;
  cursor: pointer;
}

.card-title-margin {
  text-align: center;
  margin-top: 20px;
  font-size: x-large;
}

.card-padding {
  padding: 0px;
  color: #FFFFFF;
}

.tab-item {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 1px;
  padding: 5px;
  cursor: pointer;
  height: 40px;
  color: #CCCCCC;
}

.tab-content div {
  border-radius: 5px;
  margin-top: 5px;
  /*padding: 5px;*/
}

@media (min-width: 768px) {
  .row.justify-content-center {
    flex-wrap: nowrap;
  }
}

a:hover {
  color: #FFFFFF !important;
  text-decoration: none !important;
  background-color: #2f5394;
  cursor: pointer;
}

.btn-primary {
  border-radius: 40px !important;
  background-color: #2F5394;
}
</style>

