<template>
  <div style="background-image: url('img/product/bigPic.png');background-size: cover;background-repeat: no-repeat;
background-position: center center;background-attachment: fixed">
    <div class="container">
      <div style="padding: 30px 0 30px 40px;">
        <div style="font-size: 20px; font-weight: bold;color: #FFFFFF">{{ this.$t('uaecn.page_main.title_news') }}</div>
        <div style="font-size: 14px; color: #FFFFFF">
          {{ this.$t('uaecn.page_main.title_news_sub') }}
        </div>
      </div>
      <div style="margin: 0 auto">
        <el-card>
          <div>
            <div class="row">
              <div class="col-sm-12 col-lg-6" style="display: flex;flex-wrap: wrap">
                <div class="col-sm-12 col-lg-6" v-for="(item,index) in newsPic" :key="index" style="cursor: pointer"
                     @click="openNews(item)">
                  <div class="effectsNews">
                    <img :src="item.headerImage" style="height: 168px;width: 300px;border-radius: 6px;"/>
                    <div class="news">
                      {{ item.webTitle }}
                    </div>
                    <div class="newsTime">{{ item.newsTime }}</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-auto col-lg-6 content">
                <div class="col-sm-auto col-lg-12" v-for="(item,index) in newsList" :key="index" style="cursor: pointer"
                     @click="openNews(item)">
                  <div class="effectsNews">
                    <div class="newsHead">
                      {{ item.webTitle }}
                    </div>
                    <div class="newsTime" style="margin-bottom: 20px;">{{ item.newsTime }}</div>
                  </div>
                </div>
                <div style="float: right;color: #2F5394;cursor: pointer;" @click="goNews">
                  {{ this.$t('uaecn.page_main.button_reg4') }}
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="space-50"></div>
    </div>
  </div>
</template>
<script>
import zhJson from '@/public/queryHomePageList_zh.json';
import enJson from '@/public/queryHomePageList_en.json';

export default {
  data() {
    return {
      newsPic: [],
      newsList: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    let that = this;
    window.addEventListener("setItemEvent", function (e) {
      if (e.key === "change-language") {
        setTimeout(() => {
          that.getList();
        }, 100)
      }
    })
  },
  methods: {
    getList() {
      let value = localStorage.getItem('change-language');
      if (!value) {
        value = 'zh';
      }
      let opt = new FormData();
      opt.append("language", value);
      this.$axios
          .post(
              "https://news.mycntrade.com/api/za/queryHomePageList", opt
          )
          .then((res) => {
            this.newsPic = res.data.slice(0, 4);
            this.newsList = res.data.slice(4, 10);
          });
      //   if (value == 'en') {
      //     this.newsPic = enJson.data.slice(0, 4);
      //     this.newsList = enJson.data.slice(4, 10);
      //   }else {
      //       this.newsPic = zhJson.data.slice(0, 4);
      //       this.newsList = zhJson.data.slice(4, 10);
      //   }
    },
    goNews() {
      this.$router.push({name: 'news'});
    },
    openNews(item) {
      this.$router.push({name: 'trendsNewsInfo', params: {webNews: item}});
    },
  }
};
</script>
<style lang="scss" scoped>
a {
  cursor: pointer;
}

::v-deep .el-card__body {
  padding: 60px;
}

@media screen and (max-width: 500px) {
  ::v-deep .el-card__body {
    padding: 20px;
    display: flex;
  }
}

/* 添加此样式以创建悬停效果 */
.effectsNews {
  transition: background-color 0.3s ease, transform 0.3s ease; /* 添加平滑的过渡效果 */
}

/* .news:hover 样式添加了悬停效果，更改了背景颜色和向上移动 */
.effectsNews:hover {
  transform: translateY(-5px); /* 向上移动5像素，可以根据需要调整值 */
}

.news {
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  line-height: 20px;
  margin: 5px 0;
  width: 288px;
}

.newsTime {
  color: #999999;
  font-size: 14px;
  margin-bottom: 10px;
}

.newsHead {
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 8px;
}

@media screen and (max-width: 500px) {
  .newsHead {
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.title {
  display: block;
  font-size: 20px;
  color: #FFFFFF;
}

@media screen and (max-width: 500px) {
  .title {
    display: none;
    font-size: 20px;
    color: #999999;
  }
}

.subtitle {
  display: block;
  font-size: 16px;
  color: #FFFFFF;
  margin: 10px 0;
}

@media screen and (max-width: 500px) {
  .subtitle {
    display: none;
    font-size: 16px;
    color: #999999;
    margin: 10px 0;
  }
}

.content {
  display: block;
}

@media screen and (max-width: 500px) {
  .content {
    display: none;
  }
}
</style>
