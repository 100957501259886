<template>
  <div
      class="section section-content "
      style="background-image: url('img/bg11.jpg'); background-size: cover; background-position: top center;"
  >
    <div class="container">
      <div class="row">
      </div>
      <div class="col text-center">
        <router-link
            to="/login"
            class="btn btn-simple btn-round btn-white btn-lg"
        >
            link page
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { FormGroupInput, Button} from '@/components';

export default {
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  }
};
</script>
<style></style>
