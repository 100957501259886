<template>
  <navbar
      position="fixed"
      type="primary"
      :transparent="transparent"
      :color-on-scroll="colorOnScroll"
      menu-classes="ml-auto"
  >
    <template>
      <router-link class="navbar-brand" to="/" style="padding: 0">
        <img src="img/product/homeHead.png" style="height: 43px;"/>
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link class="nav-link" to="/">
          <p>{{ $t('uaecn.header.menu_main') }}</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/intro">
          <p>{{ $t('uaecn.header.menu_intro') }}</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/news">
          <p>{{ $t('uaecn.header.menu_news') }}</p>
        </router-link>
      </li>
      <li class="nav-item">
        <el-dropdown>
       <span class="el-dropdown-link nav-link" style="font-size: 16px;color: #FFFFFF">
      {{ $t('uaecn.header.menu_service') }}<i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
          <el-dropdown-menu slot="dropdown">
            <router-link to="/serviceGSC">
              <el-dropdown-item>
                <i class="fa fa-star" aria-hidden="true"></i> {{ $t('uaecn.header.menu_sub_pol') }}
              </el-dropdown-item>
            </router-link>
            <router-link to="/serviceITSC">
              <el-dropdown-item>
                <i class="fa fa-globe" aria-hidden="true"></i> {{ $t('uaecn.header.menu_sub_tra') }}
              </el-dropdown-item>
            </router-link>
            <router-link to="/serviceIIFSC">
              <el-dropdown-item>
                <i class="fa fa-university" aria-hidden="true"></i> {{ $t('uaecn.header.menu_sub_inv') }}
              </el-dropdown-item>
            </router-link>
            <router-link to="/serviceIFSC">
              <el-dropdown-item>
                <i class="fa fa-credit-card" aria-hidden="true"></i> {{ $t('uaecn.header.menu_sub_pay') }}
              </el-dropdown-item>
            </router-link>
            <router-link to="/serviceICECC">
              <el-dropdown-item>
                <i class="fa fa-graduation-cap" aria-hidden="true"></i> {{ $t('uaecn.header.menu_sub_cul') }}
              </el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/contactUs">
          <p>{{ $t('uaecn.header.menu_contact') }}</p>
        </router-link>
      </li>
<!--      <li class="nav-item">-->
<!--        <a href="https://scocsp.com/" class="nav-link" target="_blank">-->
<!--          <div style="color: #FFFFFF">{{ $t('uaecn.header.menu_back') }}</div>-->
<!--        </a>-->
<!--      </li>-->
      <li class="nav-item nav-link">
        <a href="https://platform.uaecntrade.com/">
          <el-button size="mini" round>{{ $t('uaecn.header.button_reg') }} / {{
              $t('uaecn.header.button_login')
            }}
          </el-button>
        </a>
      </li>
      <li class="nav-item nav-link">
        <change-language style="color: #FFFFFF"></change-language>
      </li>
    </template>
  </navbar>
</template>

<script>
import {Navbar} from '@/components';
import ChangeLanguage from '@/components/i18n/change-language';

export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    ChangeLanguage
  },
  methods: {}
};
</script>

<style scoped>

p {
  color: #FFFFFF;
}

a {
  color: #666666 !important;
}

a:hover {
  text-decoration: none;
}

.el-dropdown-menu__item:hover a {
  background-color: transparent !important;
  color: #66b1ff !important;
}
</style>
