<template>
  <div id="app">
    <router-view name="header"/>
    <div class="wrapper">
      <router-view/>
    </div>
    <router-view name="footer"/>
  </div>
</template>
<script>
export default {};
</script>

<style>
/** {
  text-align: justify;
}*/
body, div, p {
  color: #333333;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif !important;
}
</style>
