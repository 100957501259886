import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// 各个国家的key
const localeKeys = ['en', 'zh']

// 各个国家语言包
const messages = {}
for (const key of localeKeys) {
  const langObj = require(`./locales/${key}/index.js`).default
  const langElement = require(`./element/${key}`)
  messages[key] = {
    ...langObj,
    ...langElement ? langElement.default : {}
  }
}

export default new VueI18n({
  locale: localStorage.getItem('change-language') || 'zh',
  messages,
  silentTranslationWarn: true // 忽略翻译警告
})

