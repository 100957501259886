// 中文
exports.default = {
  'Language': '简体中文',
  uaecn: {
    header: {
      menu_main: '首页',
      menu_intro: '平台简介',
      menu_news: '动态要闻',
      menu_service: '产业生态',
      menu_contact: '联系我们',
      menu_back: '返回官网',

      menu_sub_pol: '中阿政务服务中心',
      menu_sub_tra: '国际贸易服务中心',
      menu_sub_inv: '国际投融资服务中心',
      menu_sub_pay: '国际金融结算中心',
      menu_sub_cul: '国际文化教育合作中心',

      menu_sub2_pol: '中阿政务服务中心',
      menu_sub2_tra: '国际贸易服务中心',
      menu_sub2_inv: '国际投融资服务中心',
      menu_sub2_pay: '国际金融结算中心',
      menu_sub2_cul: '国际文化教育合作中心',

      button_reg: '注册',
      button_login: '登录',
      published_date: '发布时间：',
      source: '来源：'
    },
    footer: {
      title: '中国-阿联酋 经贸综合服务平台',
      address: '联系地址：北京市朝阳区东三环中路9号 富尔大厦 703A',
      email: '联系邮箱：service@dixchain.com',
      ICP: '京ICP备20005006号-3',
      company: '北京智链优创科技有限公司'
    },
    page_main: {
      bar_pol: '中阿政务服务',
      bar_tra: '国际贸易服务',
      bar_inv: '国际投融资服务',
      bar_pay: '国际金融结算',
      bar_cul: '国际文化教育',

      title_service: '平台生态赋能产业',
      title_service_sub: '为入驻企业、合作伙伴、提供全方位、多层次、高质量服务',
      title_news: '动态要闻',
      title_news_sub: '最新中阿新闻，实时速递',
      title_support: '中阿跨境综合服务',
      title_support_sub: '切实做好跨境企业的服务工作，打造领先的服务模式',
      title_double_title1: '阿联酋 · 迪拜',
      title_double_title2: '中国 · 青岛',

      card_pol_t: '中阿政务服务中心',
      card_pol_c: '帮助中国及阿联酋的企业家实现跨境业务出海，实现“一键双园入驻”。为企业提供多种基于“中阿双园”的政务、税务服务，例如：快速企业注册、中阿政策咨询服务、中阿税务服务、中阿签证绿色通道等。',
      card_tra_t: '国际贸易服务中心',
      card_tra_c: '在中国及阿联酋国家及地区间经贸合作基础上，拓展中阿贸易合作，搭建国际贸易发展平台，以数字化手段推动国际贸易服务便利化。',
      card_inv_t: '国际投融资服务中心',
      card_inv_c: '聚合金融机构和相关服务业，结合贸易大数据，全面提升国际资本投资、国际贸易融资、保险等金融服务便利化水平。',
      card_pay_t: '国际金融结算中心',
      card_pay_c: '汇集中阿两国的国家、地区经贸和金融服务需求，面向中阿贸易企业提供一站式国际金融结算服务。',
      card_cul_t: '国际文化教育中心',
      card_cul_c: '扎根中国、融通中外，构建国内国际一体化的文化教育领域的合作交流平台、资源共享平台、创新示范平台、人才交流平台、产业发展平台。',

      service_icon01: '设立中阿跨国企业',
      service_icon02: '设立中阿银行账户',
      service_icon03: '贸易融资和IPO服务',
      service_icon04: '中阿贸易投资服务',
      service_icon05: '中阿跨境采购',
      service_icon06: '中阿一站通关',
      service_icon07: '中阿全链物流',
      service_icon08: '中阿签证办理',

      support_a1_t: '迪拜纳斯达克IPO平台',
      support_a1_st: '海外上市直通车',
      support_a1_c: '帮助企业把控资本市场发展方向，零风险、低成本IPO以及获得国际资本，助力企业数字转型、赋能企业科技创新，为企业实体经济升级提供更丰沃的资本“土壤”。',

      support_a2_t: '中东跨境电子商务综合试验区',
      support_a2_st: '为客户提供一站式跨境电商服务',
      support_a2_c: '由迪拜硅谷自贸区打造的“跨境电子商务综合试验区”，坐落在迪拜硅谷，为客户提供高质量的商品展示、交易、海外仓、供应链金融、物流等一系列服务。让海内外客户享受链接阿联酋、海湾合作委员会各国和全球，国内-国际端的综合配套服务。',

      support_a3_t: '全球数字产业投创平台暨投资峰会',
      support_a3_st: '一年一度的全球投资峰会',
      support_a3_c: '每年汇聚万余家企业，搭建资本与项目的桥梁，创造产业发展的新机遇，树立了全球投资领域的标杆，成为引领产业变革的关键推动力。',

      support_b1_t: '青岛 · 中阿互贸示范区',
      support_b1_st: '中阿互贸示范园区',
      support_b1_c: '中国-阿联酋互贸产业示范园区，位于中国青岛市，园区将遵循互相尊重、公平公正、互利共赢的健康发展原则，致力推动两国经贸往来，促进区域经济繁荣，为企业提供良好的投资环境和合作机会。',

      support_b2_t: '青岛 · 政府资源支持',
      support_b2_st: '中国青岛各级政府支持',
      support_b2_c: '中国政府提供了一系列的支持措施，包括：简化审批流程、提供税务优惠、加强政策支持等。中国政府的支持为中国和阿联酋的企业提供了更加便捷、高效的政务服务，促进了两国经贸合作的发展。',

      support_b3_t: '中国 · 众多资金机构',
      support_b3_st: '中国众多资金机构支持',
      support_b3_c: '中国境内银行及资金机构也对中阿综合服务平台提供支持。提供投资、融资、上市等金融服务，包括：创新金融产品、优化服务流程、加强风险控制。为中国和阿联酋的企业提供了更加多元化、便捷化的金融服务，促进了两国经贸合作的发展。',

      button_reg1: '查看详情',
      button_reg2: '立即注册，了解详情',
      button_reg3: '立即注册',
      button_reg4: '查看全部',
    },
    page_intro: {
      page_title: '中国-阿联酋经贸综合服务平台',
      page_title_sub: '立足中阿 · 服务全球',
      content_t1: '背景概述',
      content_t1_p1: '近年来，中国与阿联酋国家间合作持续深化，中国已连续多年成为阿联酋的第一大贸易伙伴，阿联酋则是中国在阿拉伯地区第二大贸易伙伴、第一大出口市场和第一大投资目的地。 ',
      content_t1_p2: '中国与阿联酋两国贸易合作持续升温，根据我国官方统计数据： 近年来，中阿贸易总额保持持续高速增长，2022年中国与阿联酋中阿贸易额达992.7亿美元，同比增长37.4%。其中，中方出口538.6亿美元，同比增长23.3%，进口454.1亿美元，同比增长58.9%。2023年1至11月，中阿贸易额470.1亿美元，同比增长2.4%。',
      content_t1_p3: '在中阿两国合作持续深化，贸易合作持续升温的大背景下，构建中国-阿联酋国际合作创新综合服务平台，以数字化手段助力两国文化、政务、贸易、金融等领域协作，势在必行。',
      content_t1_p4: '基于以上背景，打造中国-阿联酋经贸综合服务平台，借助科技手段为中国及阿联酋企业提供中阿两国政务服务、国际贸易、国际投融资、国际金融结算以及国际文化教育交流合作等服务，帮助中国企业拓展阿联酋乃至中东市场，帮助阿联酋企业走进中国，加强国际合作。',
      content_t2: '核心价值',
      content_t2_p1: '中国-阿联酋经贸综合服务平台，致力推动两国乃至中国与中东地区的经贸往来，促进区域经济繁荣。平台将遵循互相尊重、公平公正、互利共赢、共同发展的原则，旨在推动两国多层次、多领域的合作，尤其在经济领域加强联系，破除市场壁垒，扩大贸易和投资，实现互补优势、共同发展，同时也促进平等和互利的国际贸易环境的建立。'
    },
    page_news: {
      page_title: '动态要闻',
      page_title_sub1: '公告',
      page_title_sub2: '新闻',
      page_title_sub3: '政策'
    },
    page_service_pol: {
      page_title: '产业生态',
      page_title_sub: '中阿政务服务中心',
      content_t1: '中阿政务服务中心',
      content_t1_p1: '帮助中国及阿联酋的企业家实现跨境业务出海，实现“一键双园入驻”。为企业提供多种基于“中阿双园”的政务、税务服务，例如：快速企业注册、政策咨询服务、税务服务、签证绿色通道等。',
      card1_t: '中阿政策咨询',
      card1_c: '中国及阿联酋政府、贸易、金融、海关、产业园区等相关政策智能化咨询服务。',
      card2_t: '中阿税务服务',
      card2_c: '中国及阿联酋税务智能咨询服务，帮助企业快速获取最优税筹政策，了解最新动态。',
      card3_t: '企业快速注册',
      card3_c: '中国及阿联酋企业快速注册，线上办理，一站式服务，实现“一键双园”。',
      card4_t: '签证便利服务',
      card4_c: '中国及阿联酋签证办理服务，旅游签/商务签线上受理，快速出签，一站式服务。',
      button: '立即注册'
    },
    page_service_tra: {
      page_title: '产业生态',
      page_title_sub: '国际贸易服务中心',
      content_t1: '国际贸易服务中心',
      content_t1_p1: '在中国及阿联酋国家及地区间经贸合作基础上，拓展中阿贸易合作，搭建国际贸易发展平台，以数字化手段推动国际贸易服务便利化。',
      button: '立即注册，了解详情'
    },
    page_service_inv: {
      page_title: '产业生态',
      page_title_sub: '国际投融资服务中心',
      content_t1: '国际投融资服务中心',
      content_t1_p1: '致力于为中国-阿联酋两国企业提供投资和融资方面的支持和合作机会。',
      card1_t: '评估中阿投资环境',
      card1_c: '提供关于两国市场的分析和预测，帮助企业制定更有效的市场进入策略， 分析中阿两国的投资环境，为投资者提供明智的决策支持。',
      card2_t: '促进中阿投资合作',
      card2_c: '通过搭建投资合作渠道，促进中阿双方企业之间的合作与沟通，推动双方投资项目的洽谈和合作。',
      card3_t: '提供贸易融资服务',
      card3_c: '利用中阿的金融服务机构资源，拓宽企业的融资渠道，为中阿贸易企业与各类金融资源有效对接提供便利的通道。',
      card4_t: '推动中阿政策合作',
      card4_c: '与两国政府的相关机构合作，为两国企业提供相关领域投资和融资的政策咨询服务，为企业提供有利的政策环境支持。',
      button: '立即注册'
    },
    page_service_pay: {
      page_title: '产业生态',
      page_title_sub: '国际金融结算中心',
      content_t1: '国际金融结算中心',
      content_t1_p1: '实现“开立跨境同名收款账户、结汇、境内提现”全流程，一站式外贸收款结汇方案。',
      content_t2: '外币结算',
      content_t2_p1: '中国人民银行和阿联酋中央银行在香港续签了规模为350亿人民币/180亿迪拉姆（49亿美元）的货币互换协议，将中阿货币互换协议延长五年，双方续签的协议将促进中阿贸易并加强两国之间的贸易合作，并将促进当地货币向金融市场提供流动性， 以“更有效和高效的方式” 实现跨境结算和跨境贸易。',
      content_t3: '货币互换',
      content_t3_p1: '全国自贸区首笔阿联酋迪拉姆结算业务落地青岛互贸区。实现了跨境结算小币种的新突破，增强了企业拓展新业务的信心。',
      content_t4: '政策研究',
      content_t4_p1: '中阿两国签署《关于加强央行数字货币合作的谅解备忘录》。随着双方贸易的加深，在国际贸易中将会促成使用数字货币直接结算，从而推进数字货币结算体系完善。',

      card1_t: '多币种',
      card1_c: '支持10余个主流币种入账。自主锁汇，避免汇率风险。最优汇率路由，降低换汇成本。',
      card2_t: '限时免费',
      card2_c: '免费国际支付账户，账户实时激活，一次注册，直接免费开通3个境外账户。',
      card3_t: '一键分账',
      card3_c: '支持采购协议分账功能，支持供应商分账，提供采购协议，即可直接提现到供应商公户、法人。',
      card4_t: '全球付',
      card4_c: '支持全球主流国家出口业务支付，部分国家及地区除外。',
      card5_t: '多种方式',
      card5_c: '多种支付方式（电汇/TT/CHATS），到账时效快提交PI+物流，审核通过后，可实时提现。',
      card6_t: '安全合规',
      card6_c: '跨境支付，安全合规。合法合规经营，成熟风控机制，反洗钱管理。',

      button: '了解详情'
    },
    page_service_cul: {
      page_title: '产业生态',
      page_title_sub: '国际文化教育合作中心',
      content_t1: '国际文化教育合作中心',
      content_t1_p1: '扎根中国、融通中外，构建国内国际一体化的文化教育领域的合作交流平台、资源共享平台、创新示范平台、人才交流平台、产业发展平台。',

      card1_t: '学术交流',
      card1_c: '为中阿科研合作提供便利化平台，组织学术访问，筹备学者交换计划，以促进两国之间的学术交流及合作。',
      card2_t: '文化交流',
      card2_c: '举办文化活动、艺术展览、演出和座谈会，通过艺术、音乐和传统文化等活动增进两国之间的文化了解。',
      card3_t: '政策研究',
      card3_c: '提供关于中阿两国贸易政策的深入研究，并根据企业需求，提供合理化建议，帮助企业适应两国的贸易环境。',
      card4_t: '人才培训',
      card4_c: '为合作领域内的商务人员提供市场规则、财务和法律方面的培训，确保企业在该区域合法合规地拓展业务。',

      button: '了解详情'
    },
    page_contact: {
      page_title: '联系我们',
      page_title_sub: '立足中阿 · 服务全球',
      title: '中国-阿联酋 经贸综合服务平台',
      address0: '联系地址：',
      address1: '中国 · 北京市朝阳区东三环中路9号 富尔大厦 703A',
      address2: '迪拜 · 49GJ+3FG - Dubai Silicon Oasis - Dubai',
      email: '联系邮箱：service@dixchain.com',
      wt_t: '工作时间',
      wt_m: '周一到周五 9:00~18:00',
      wt_e: 'Monday~Friday,AM9:00~PM18:00'
    },
    phone_title: {
      title: '请使用电脑访问',
      content:　'即可使用完整功能',
    }
  }
}
