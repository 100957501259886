<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/product/pc_visited.png')"
      >
      </parallax>
    </div>
    <div class="login">
      <div>{{ this.$t('uaecn.phone_title.title') }}</div>
      <div><span>{{ url }}</span></div>
      <div>{{ this.$t('uaecn.phone_title.content') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "www.uaecntrade.com",
    };
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.url)
          .then(() => {
            this.$message('复制成功');
          })
          .catch(() => {
            this.$message('复制失败');
          })
    },
  },
};
</script>

<style lang="scss" scoped>

.login {
  margin-left: 20px;
  margin-top: 20px;
}

div {
  font-size: 16px;
  color: #666;

  span {
    color: #2a50b7;
  }
}
</style>