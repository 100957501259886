<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/product/news.png')"
      >
      </parallax>
        <div class="container">
          <div class="title2">{{ this.$t('uaecn.page_news.page_title') }}</div>
          <div class="text-center">
            <span>{{ this.$t('uaecn.page_news.page_title_sub1') }}</span>
            <span> / </span>
            <span>{{ this.$t('uaecn.page_news.page_title_sub2') }}</span>
            <span> / </span>
            <span>{{ this.$t('uaecn.page_news.page_title_sub3') }}</span>
          </div>
        </div>
    </div>
    <div class="container" style="display: flex">
      <div class="row" style="width: 100%">
        <div class="col-12 myBorder">
          <span class="mySpan" @click="back()" style="cursor: pointer"
          ><i class="el-icon-arrow-left"></i>返回</span
          >
          <div class="title">{{ news.webTitle }}</div>
          <div style="width: 100%; height: 30px">
            <span class="mySpan">{{ this.$t('uaecn.header.published_date') }}</span>
            <span class="mySpan"> {{ news.newsTime }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="mySpan">{{ this.$t('uaecn.header.source') }}</span>
            <span class="mySpan"> {{ news.newsSource }}</span>
          </div>
          <div style="border-bottom: 1px solid #dddddd; margin: 20px 0"></div>
          <div class="mycontent" v-html="news.context"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "context",
  components: {},
  data() {
    return {
      news: {},
    };
  },
  mounted() {
    if (this.$route.params.webNews) {
      this.news = this.$route.params.webNews;
      window.localStorage.setItem('news', JSON.stringify(this.$route.params.webNews))
    } else {
      // 从 localStorage 中获取数据
      const storedData = localStorage.getItem('news');
      // 解析 JSON 字符串
      const parsedData = JSON.parse(storedData);
      // 如果数据存在，可以将其赋值给组件的数据
      if (parsedData) {
        this.news = parsedData;
      }
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
::v-deep p {
  font-weight: 400 !important;
}

.title2 {
  color: #FFFFFF;
  font-size: 30px;
  font-weight: 700;
  padding-top: 30px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.text-center {
  color: #FFFFFF;
  font-size: 26px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.container {
  max-width: 1180px !important;
}

.title {
  font-size: calc(10px + 1vw);
  text-align: center;
  margin-bottom: 20px;
}

.mySpan {
  font-size: calc(10px + 0.5vw);
  color: #999999;
}

.mycontent {
  font-weight: 400;
  font-size: calc(10px + 0.4vw);
  color: #999999;
}

.row {
  margin: 40px;
}

@media screen and (max-width: 768px) {
  .row {
    margin: 0px;
  }
}

.myBorder {
  padding: 40px 60px;
  border: solid 1px #ddd;
}

@media screen and (max-width: 768px) {
  .myBorder {
    padding: 24px;
    border: solid 1px #ddd;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px;
  }
}
</style>
