<template>
    <div style="background: #f6f6f6; margin: 0 0">
  <div class="container">
    <div class="space-50"></div>
    <div style="padding: 30px 0 30px 40px;">
      <div style="font-size: 20px; font-weight: bold;color: #333333">{{this.$t('uaecn.page_main.title_support')}}</div>
      <div style="font-size: 14px; color: #666666">
        {{this.$t('uaecn.page_main.title_support_sub')}}
      </div>
    </div>
      <div class="row justify-content-center" style="margin: 0 0">
        <div
            class="col-3 col-sm-3 col-lg-2"
            v-for="(item, index) in serviceInfo"
            :key="index"
            style="text-align: center;display: flex; flex-direction: column; align-items: center"
        >
          <div class="round-container">
            <img class="circle" :src="item.pic"/>
            <div class="myFont">{{ item.head }}</div>
          </div>
        </div>
      </div>
      <div style="text-align: center;margin-top: 20px;">
        <el-button @click="openPlatform()" style="padding: 20px 40px;border-radius: 30px;font-size: 16px;">{{this.$t('uaecn.page_main.button_reg3')}}</el-button>
      </div>
    </div>
      <div class="space-50"></div>
  </div>
</template>
<script>
import config from "@/config";

const platformUrl = config.platformUrl;
export default {
  data() {
    return {
      serviceInfo: [
        {pic: "img/product/service1.png", head: this.$t('uaecn.page_main.service_icon01')},
        {pic: "img/product/service2.png", head: this.$t('uaecn.page_main.service_icon02')},
        {pic: "img/product/service3.png", head: this.$t('uaecn.page_main.service_icon03')},
        {pic: "img/product/service4.png", head: this.$t('uaecn.page_main.service_icon04')},
        {pic: "img/product/service5.png", head: this.$t('uaecn.page_main.service_icon05')},
        {pic: "img/product/service6.png", head: this.$t('uaecn.page_main.service_icon06')},
        {pic: "img/product/service7.png", head: this.$t('uaecn.page_main.service_icon07')},
        {pic: "img/product/service8.png", head: this.$t('uaecn.page_main.service_icon08')},
      ],
    };
  },
  methods: {
    openPlatform() {
      if (window.innerWidth <= 760) {
        this.$router.push('/login')
      }else {
        // 使用 window.open 打开新窗口
        window.open(platformUrl, '_blank');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-button {
  background: linear-gradient(to bottom, #2F5394, #4488F5);
  color: #ffffff;
  font-size: 18px;
}

::v-deep .el-card__body {
  padding: 40px;
}

@media screen and (max-width: 500px) {
  ::v-deep .el-card__body {
    padding: 20px;
    display: flex;
  }
}

.circle {
  width: 88px;
  height: 88px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  margin: 20px auto;
  min-width: 90px;
  padding: 15px;
  border: solid 1px #dddddd;
  transition: filter 0.3s ease-in-out;
}

.myFont {
  font-size: calc(6px + 0.4vw);
  line-height: 20px;
  margin-bottom: 20px;
}

.tab-content.tab-content-padding {
  padding: 20px;
}

.col-lg-2 {
  flex: 0 0 12.5%;
}

.round-container {
  position: relative;
  transition: background-color 1s ease-in-out;
}

.circle:hover {
  background-color: #EBF3FF; /* 鼠标悬停时改变背景颜色，可以根据需要调整 */
  border: solid 2px #2f5394;
}
</style>
