<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/product/news.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <div class="title">{{ this.$t('uaecn.page_news.page_title') }}</div>
          <div class="text-center">
            <span>{{ this.$t('uaecn.page_news.page_title_sub1') }}</span>
            <span> / </span>
            <span>{{ this.$t('uaecn.page_news.page_title_sub2') }}</span>
            <span> / </span>
            <span>{{ this.$t('uaecn.page_news.page_title_sub3') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="display: flex">
      <div>
        <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            style="margin-top: 10px;"
            mode="vertical"
            @select="handleSelect"
        >
          <el-menu-item style="font-size: calc(9px + 0.5vw);padding: 0 40px;" v-for="(item,key) in queryTypes"
                        :key="key" :index="item.dictCode.toString()">{{
              item.dictLabel
            }}
          </el-menu-item>
        </el-menu>
      </div>
      <div>
        <div
            class="row news effectsNews"
            v-for="(item, index) in newsList"
            :key="index"
            @click="openNews(item)"
            style="cursor: pointer; margin: 0 0"
        >
          <div class="col-sm-4 col-lg-4">
            <img :src="item.headerImage" style="width: 300px;height: 170px;border-radius: 6px;"/>
          </div>
          <div class="col-sm-8 col-lg-8">
            <div>
              <div style="font-size: calc(10px + 0.5vw);font-weight: bold;color: #2f5394;line-height: 30px">
                {{ item.webTitle }}
              </div>
              <div
                  style="
                  font-size: calc(9px + 0.3vw);
                  color: #999999;
                  font-weight: bold;line-height: 30px
                "
              >
                <span>{{ item.newsTime }}</span>
              </div>
              <div style="font-size: calc(9px + 0.3vw); color: #999999;line-height: 30px">
                {{ item.introduction }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
        class="pageStyle"
        background
        layout="prev, pager, next"
        :page-size="10"
        :current-page="current"
        @current-change="handleCurrentChange"
        :total="total"
        v-if="total > 10"
    >
    </el-pagination>
  </div>
</template>
<script>
import enType from '@/public/web_column_manager_en.json';
import zhType from '@/public/web_column_manager_zh.json';
import zhDownload from '@/public/queryTypeList_download_materials_zh.json';
import enGovernment from '@/public/queryTypeList_government_affairs_news_en.json';
import zhGovernment from '@/public/queryTypeList_government_affairs_news_zh.json';
import enAnnouncement from '@/public/queryTypeList_website_announcement_en.json';
import zhAnnouncement from '@/public/queryTypeList_website_announcement_zh.json';

export default {
  name: "context",
  components: {},
  data() {
    return {
      activeIndex: null,
      queryTypes: [],
      typeId: null,
      newsList: [],
      current: 1,
      total: 0,
    };
  },
  created() {
    this.getQueryTypes();
  },
  mounted() {
    let that = this;
    window.addEventListener("setItemEvent", function (e) {
      if (e.key === "change-language") {
        setTimeout(() => {
          that.getQueryTypes();
          that.getList();
        }, 100)
      }
    })
  },
  methods: {
    // getQueryTypes() {
    //   let opt = new FormData();
    //   var language = localStorage.getItem('change-language');
    //   if (language === null) {
    //     language = 'zh'; // 设置默认值
    //   }
    //   opt.append("language", language);
    //   this.$axios
    //       .post("https://platform.uaecntrade.com/api/web/business/queryTypes", opt).then((res) => {
    //     this.queryTypes = res.data.data;
    //     this.activeIndex = res.data.data[0].dictCode + '';
    //     this.typeId = res.data.data[0].dictValue;
    //     this.getList();
    //   })
    // },
    handleCurrentChange(val) {
      this.current = val;
      this.getList();
    },
    getQueryTypes() {
      var language = localStorage.getItem('change-language');
      if (language === null) {
        language = 'zh'; // 设置默认值
      }
      if (language == 'en') {
        this.queryTypes = enType.data;
        this.activeIndex = enType.data[0].dictCode + '';
        this.typeId = enType.data[0].dictValue;
        this.getList();
      } else {
        this.queryTypes = zhType.data;
        this.activeIndex = zhType.data[0].dictCode + '';
        this.typeId = zhType.data[0].dictValue;
        this.getList();
      }
    },
    handleSelect(key, keyPath) {
      this.typeId = this.queryTypes.find(item => item.dictCode == key).dictValue;
      this.getList();
    },
    // getList() {
    //   let type = this.typeId;
    //   if (type == 'download_materials_zh') {
    //     this.newsList = zhDownload.data.rows;
    //   } else if (type == 'government_affairs_news_en') {
    //     this.newsList = enGovernment.data.rows;
    //   } else if (type == 'government_affairs_news_zh') {
    //     this.newsList = zhGovernment.data.rows;
    //   } else if (type == 'website_announcement_en') {
    //     this.newsList = enAnnouncement.data.rows;
    //   } else if (type == 'website_announcement_zh') {
    //     this.newsList = zhAnnouncement.data.rows;
    //   } else {
    //     this.newsList = [];
    //   }
    // },
    getList() {
      let opt = new FormData();
      opt.append("columnType", this.typeId);
      opt.append("pageNum", this.current);
      opt.append("pageSize", 10);
      this.$axios
          .post(
              "https://news.mycntrade.com/api/za/queryTypeList",
              opt
          )
          .then((res) => {
            if (res) {
              this.total = res.data.count;
              this.newsList = JSON.parse(res.data.data);
            }
          });
    },
    openNews(item) {
      this.$router.push({name: 'trendsNewsInfo', params: {webNews: item}});
    },
  },
};
</script>
<style scoped>
.container {
  max-width: 1180px !important;
}

.title {
  color: #FFFFFF;
  font-size: 30px;
  text-align: center;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.text-center {
  color: #FFFFFF;
  font-size: 26px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.font {
  color: #FFFFFF;
  font-size: 26px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.news {
  padding: 40px;
  align-items: flex-start;
}

@media screen and (max-width: 500px) {
  .news {
    padding: 10px 10px 0;
    align-items: flex-start;
  }
}

.col-sm-8 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

/* 添加此样式以创建悬停效果 */
.effectsNews {
  transition: background-color 0.3s ease, transform 0.3s ease; /* 添加平滑的过渡效果 */
}

/* .news:hover 样式添加了悬停效果，更改了背景颜色和向上移动 */
.effectsNews:hover {
  transform: translateY(-5px); /* 向上移动5像素，可以根据需要调整值 */
}

.pageStyle {
  margin-bottom: 20px;
  text-align: center;
}
</style>
