<template>
  <div class="container">
    <div class="row justify-content-center">
      <div
          class="col-6 col-md-4 col-lg-2 effectsNews"
          v-for="(item, index) in TagInfo"
          :key="index"
      >
        <div class="tag" :style="{ backgroundColor: item.color }">
          <img :src="item.pic" class="rotate" v-if="index < 5"/>
          <div class="rotateSize">{{ item.head }}</div>
        </div>
      </div>
    </div>
    <div class="space-50"></div>
  </div>
</template>
<script>
import {Button} from "@/components";

export default {
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      TagInfo: [
        {
          pic: "img/product/main1.png",
          head: this.$t('uaecn.page_main.bar_pol'),
          color: "#6EDED5",
        },
        {
          pic: "img/product/main2.png",
          head: this.$t('uaecn.page_main.bar_tra'),
          color: "#66CCDB",
        },
        {
          pic: "img/product/main4.png",
          head: this.$t('uaecn.page_main.bar_inv'),
          color: "#61C4E2",
        },
        {
          pic: "img/product/main3.png",
          head: this.$t('uaecn.page_main.bar_pay'),
          color: "#5EC1EA",
        },
        {
          pic: "img/product/main6.png",
          head: this.$t('uaecn.page_main.bar_cul'),
          color: "#5EAFEA",
        }
      ]
    };
  },
};
</script>
<style lang="scss" scoped>
.rotate {
  transform: skew(30deg);
  width: 50px;
}

.rotateSize {
  color: #ffffff;
  margin-left: 10px;
  font-size: calc(9px + 0.35vw);
  transform: skew(30deg);
}

@media screen and (max-width: 1200px) {
  .rotateSize {
    font-size: calc(6px + 0.35vw);
    margin-left: 5px;
    transform: skew(30deg);
  }
}

@media screen and (max-width: 768px) {
  .rotate {
    display: none; /* 在小屏幕上隐藏图片 */
  }

  .rotateSize {
    font-size: 0.9em;
    margin-left: 5px;
    transform: translateZ(10px);
  }

  .row > .col:nth-child(5) {
    justify-content: flex-start;
  }
}

.tag {
  transform: skew(-30deg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .tag {
    transform: skew(0deg);
    margin-top: 20px;
    border-radius: 2px;
  }
}


.container {
  padding: 0 15px;
}

.row {
  margin: 0 0px;
}

@media screen and (max-width: 768px) {
  .row {
    margin: 0;
  }
}

/* 添加此样式以创建悬停效果 */
.effectsNews {
  transition: background-color 0.3s ease, transform 0.3s ease; /* 添加平滑的过渡效果 */
}

/* .news:hover 样式添加了悬停效果，更改了背景颜色和向上移动 */
.effectsNews:hover {
  transform: translateY(-5px); /* 向上移动5像素，可以根据需要调整值 */
}

.col-lg-2 {
  flex: 0 0 17.666667%;
  max-width: 17.666667%;
}

</style>
