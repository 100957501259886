<template>
  <footer
      class="footer"
      :class="{ [`footer-${type}`]: type }"
      :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <div style="font-size: 16px;color: #FFFFFF;">
            {{ this.$t('uaecn.footer.title') }}
          </div>
          <div style="font-size: 14px;color: #FFFFFF;">
            {{ this.$t('uaecn.footer.address') }}
          </div>
          <div style="font-size: 14px;color: #FFFFFF;">{{ this.$t('uaecn.footer.email') }}</div>
        </div>
        <div class="col-sm-12 col-lg-6" style="text-align: center;">
          <img src="img/product/foot1.png" class="col-6 col-sm-4 col-lg-3" style="padding: 5px;"/>
          <img src="img/product/foot2.png" class="col-6 col-sm-4 col-lg-3" style="padding: 5px;"/>
          <img src="img/product/foot3.png" class="col-6 col-sm-4 col-lg-3" style="padding: 5px;"/>
          <!--          <img src="img/product/foot4.png" class="col-6 col-sm-4 col-lg-3" style="padding: 5px;" />-->
        </div>
      </div>
      <div style="margin-top: 30px;"></div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <div style="color: #FFFFFF;">{{ this.$t('uaecn.footer.company') }}</div>
        </div>
        <div class="col-auto">
          <div style="color: #FFFFFF;">
            <img src="img/product/police.png" style="width: 24px;height:24px;" />&nbsp;
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11010502054712\" rel="noreferrer" target="_blank">京公网安备11010502054712</a>
          </div>
        </div>
        <div class="col-auto">
          <a href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer" target="_blank">{{ this.$t('uaecn.footer.ICP') }}</a>
<!--          <div @click="toFilings()" style="color: #FFFFFF; cursor: pointer;"> {{ this.$t('uaecn.footer.ICP') }}</div>-->
        </div>
        <!--        <img src="img/product/police.png" style="width: 20px;height: 20px;"/>-->
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {};
  },
  methods: {
    toFilings() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  }
};
</script>
