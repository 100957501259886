<template>
  <div>
    <div class="page-header">
      <el-carousel style="height: 100%;overflow-y: hidden" height="100%" indicator-position="outside">
        <el-carousel-item>
          <parallax
              class="page-header-image"
              style="background-image:url('img/product/home.png')"
          >
          </parallax>
        </el-carousel-item>
        <el-carousel-item>
          <parallax
              class="page-header-image"
              style="background-image:url('img/product/home2.png')"
          >
          </parallax>
        </el-carousel-item>
        <div class="container" style="z-index: 99">
          <div class="content-center brand">
            <!--            <img src="img/product/homeTip.png" alt=""/>-->
            <img :src="getImagePath" alt=""/>
          </div>
        </div>
      </el-carousel>
    </div>
    <!--    我们的服务-->
    <home-service></home-service>
    <!--    平台生态赋能产业-->
    <HomePlatformEcosystem></HomePlatformEcosystem>
    <!--    动态要闻-->
    <home-trends-news></home-trends-news>
    <!--    双边跨境综合服务-->
    <home-cross-border></home-cross-border>
    <home-product-show></home-product-show>
  </div>
</template>
<script>
import {Parallax} from '@/components';
import HomeService from './components/HomeService';
import HomePlatformEcosystem from './components/HomePlatformEcosystem.vue';
import HomeTrendsNews from './components/HomeTrendsNews.vue';
import HomeCrossBorder from './components/HomeCrossBorder.vue';
import HomeProductShow from './components/HomeProductShow.vue';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax,
    HomeService,
    HomePlatformEcosystem,
    HomeTrendsNews,
    HomeCrossBorder,
    HomeProductShow
  },
  computed: {
    getImagePath() {
      let value = localStorage.getItem('change-language');
      if (!value) {
        value = 'zh';
      }
      if (value == 'zh') {
        // 根据屏幕宽度选择不同的图片路径
        return window.innerWidth <= 760 ? 'img/product/phoneHomeTip.png' : 'img/product/homeTip.png';
      } else  {
        return window.innerWidth <= 760 ? 'img/product/phoneHomeTip_en.png' : 'img/product/homeTip_en.png';
      }
    }
  },
  mounted() {
    // 在组件挂载后，监听窗口大小变化，实时更新图片路径
    window.addEventListener('resize', this.updateImagePath);
  },
  beforeDestroy() {
    // 在组件销毁前，移除窗口大小变化的监听
    window.removeEventListener('resize', this.updateImagePath);
  },
  methods: {
    // 更新图片路径方法
    updateImagePath() {
      this.$forceUpdate(); // 强制更新计算属性
    }
  }
};
</script>
<style>
.container {
  max-width: 1500px !important;
}

.content-center {
  max-width: 1500px !important;
}

.myPic {
  position: absolute;
  width: 100%;
  z-index: -1;
  display: block;
}

@media screen and (max-width: 500px) {
  .myPic {
    position: absolute;
    width: 100%;
    z-index: -1;
    display: none;
  }
}

.index-page .page-header .container > .content-center {
  top: 45% !important;
}

@media screen and (max-width: 500px) {
  .index-page .page-header .container > .content-center {
    top: 37% !important;
  }
}
</style>
