<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/bg6.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">This is our great company.</h1>
          <div class="text-center">
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-google-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Who we are?</h2>
            <h5 class="description">
              According to the National Oceanic and Atmospheric Administration,
              Ted, Scambos, NSIDClead scentist, puts the potentially record low
              maximum sea ice extent tihs year down to low ice extent in the
              Pacific and a late drop in ice extent in the Barents Sea.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary">
          <context-banner></context-banner>
        </div>
        <div class="separator separator-primary">
          <context-image-text></context-image-text>
        </div>
        <div class="separator separator-primary">
          <context-text-image></context-text-image>
        </div>
        <div class="section">
          <div class="row">
            <div class="col-md-4">
              <div class="card">

                <img class="card-img-top" src="../../assets/github/angular.png"/>
                <H1 class="card-title" style="border: #18ce0f 1px solid;">

                  card-title
                </H1>
                <a class="card-subtitle" style="border: #18ce0f 1px solid;">

                  <h3> card-subtitle</h3>
                </a>
                <div class="card-text" style="border: #18ce0f 1px solid;">

                  .card-text
                </div>
                <div class="card-body" style="border: #18ce0f 1px solid;">

                  card-body
                </div>
                <a class="card-link" style="border: #18ce0f 1px solid;">

                  card-link
                </a>
              </div>

            </div>
            <div class="col-md-4">
              <div class="card">

                <img class="card-img-top" src="../../assets/github/angular.png"/>
                <H1 class="card-title" style="border: #18ce0f 1px solid;">

                  card-title
                </H1>
                <a class="card-subtitle" style="border: #18ce0f 1px solid;">

                  <h3> card-subtitle</h3>
                </a>
                <div class="card-text" style="border: #18ce0f 1px solid;">

                  .card-text
                </div>
                <div class="card-body" style="border: #18ce0f 1px solid;">

                  card-body
                </div>
                <a class="card-link" style="border: #18ce0f 1px solid;">

                  card-link
                </a>
              </div>

            </div>
            <div class="col-md-4">
              <div class="card">

                <img class="card-img-top" src="../../assets/github/angular.png"/>
                <H1 class="card-title" style="border: #18ce0f 1px solid;">

                  card-title
                </H1>
                <a class="card-subtitle" style="border: #18ce0f 1px solid;">

                  <h3> card-subtitle</h3>
                </a>
                <div class="card-text" style="border: #18ce0f 1px solid;">

                  .card-text
                </div>
                <div class="card-body" style="border: #18ce0f 1px solid;">

                  card-body
                </div>
                <a class="card-link" style="border: #18ce0f 1px solid;">

                  card-link
                </a>
              </div>

            </div>
          </div>
        </div>


      </div>
    </div>


  </div>
</template>
<script>
import {Button, FormGroupInput} from '@/components';
import ContextBanner from './ContextBanner.vue';
import ContextImageText from './ContextImageText.vue';
import ContextTextImage from './ContextTextImage.vue';

export default {
  name: 'context',
  bodyClass: 'landing-page',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ContextBanner,
    // eslint-disable-next-line vue/no-unused-components
    ContextImageText,
    // eslint-disable-next-line vue/no-unused-components
    ContextTextImage,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style></style>
