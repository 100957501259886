<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/product/pingtai-head-banner.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <div class="title">{{ this.$t('uaecn.page_intro.page_title') }}</div>
          <div class="text-center">
            {{ this.$t('uaecn.page_intro.page_title_sub') }}
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="separator separator-primary">
          <div class="section section-content"
               style=" background-size: cover; background-position: top center; min-height: 300px;">
            <div class="container">
              <!--     国际贸易服务中心   图文 开始   -->
              <div class="row">
                <div class="col-md-12 ml-auto mr-auto text-left">
                  <h3>{{ this.$t('uaecn.page_intro.content_t1') }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8 align-items-center" style="padding: 20px;">
                  <!-- Second image on the left side of the article -->
                  <div class="text-container">
                    <p>
                      {{ this.$t('uaecn.page_intro.content_t1_p1') }}
                    </p>
                    <p>
                      {{ this.$t('uaecn.page_intro.content_t1_p2') }}
                    </p>
                    <p>
                      {{ this.$t('uaecn.page_intro.content_t1_p3') }}
                    </p>
                    <p>
                      {{ this.$t('uaecn.page_intro.content_t1_p4') }}
                    </p>
                  </div>
                </div>
                <div class="col-md-4"
                >
                  <!-- Second image on the left side of the article -->
                  <img src="img/product/about-left-pic.png" style="border-radius: 8px;"/>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-primary container">
          <!--     国际贸易服务中心   图文 开始   -->
          <div class="row">
            <div class="col-md-12 ml-auto mr-auto text-left">
              <h3>{{ this.$t('uaecn.page_intro.content_t2') }}</h3>
              <p>
                {{ this.$t('uaecn.page_intro.content_t2_p1') }}
              </p>
            </div>
          </div>
          <div class="section section-content ">
            <div class="container">
              <div class="row">
                <img :src="getImagePath" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Button, FormGroupInput} from '@/components';
import ContextBanner from './ContextBanner.vue';
import ContextImageText from './ContextImageText.vue';
import ContextTextImage from './ContextTextImage.vue';

export default {
  name: 'context',
  bodyClass: 'landing-page',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ContextBanner,
    // eslint-disable-next-line vue/no-unused-components
    ContextImageText,
    // eslint-disable-next-line vue/no-unused-components
    ContextTextImage,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  },
  computed: {
    getImagePath() {
      let value = localStorage.getItem('change-language');
      if (!value) {
        value = 'zh';
      }
      if (value == 'zh') {
        // 根据屏幕宽度选择不同的图片路径
        return 'img/product/about-bottom-banner.png';
      } else {
        return 'img/product/about-bottom-banner_en.png';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.title {
  color: #FFFFFF;
  text-align: center;
  font-size: 30px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.text-center {
  color: #FFFFFF;
  font-size: 26px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

p {
  font-size: 16px;
  font-weight: 400;
}

.section {
  padding: 20px 0;
}
</style>
