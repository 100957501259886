<template>
  <div
      class="section section-content"
      style=" background-size: cover; background-position: top center; min-height: 300px;"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <!-- Second image on the left side of the article -->
          <div
              class="text-container"

          >

            <h3>
              So what does the new record for the lowest level of winter ice
              actually mean
            </h3>
            <p>
              The Arctic Ocean freezes every winter and much of the sea-ice
              then thaws every summer, and that process will continue whatever
              happens with climate change. Even if the Arctic continues to be
              one of the fastest-warming regions of the world, it will always
              be plunged into bitterly cold polar dark every winter. And
              year-by-year, for all kinds of natural reasons, there’s huge
              variety of the state of the ice.
            </p>

          </div>
        </div>
        <div class="col-md-6" style="background-image: url('img/login.jpg')">
          <!-- Second image on the left side of the article -->
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {FormGroupInput, Button} from '@/components';

export default {
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  }
};
</script>
<style></style>
