<template>
  <div class="section section-basic">
    <div class="container">
      <div class="row">
        <div class="col-md-10 ml-auto col-xl-6 mr-auto">
          <p class="category">{{ this.$t('uaecn.page_main.title_double_title1') }}</p>
          <div style="border: solid 1px #317234"></div>
          <div style="height: 20px;display: block;"></div>
          <div v-for="(item,index) in Dibai" :key="index">
            <el-card shadow="hover">
              <div class="myElCard" :lang="language">
                <div class="head" :lang="language">{{ item.head }}</div>
                <div class="subtitle">{{ item.subtitle }}</div>
                <div style="display: flex;margin-top: 10px;">
                  <div><img :src="item.pic" class="myImg"/></div>
                  <div class="myFont" :lang="language">
                    {{ item.content }}
                  </div>
                </div>
              </div>
            </el-card>
            <div v-if="index != 2" style="margin-top: 20px;"></div>
          </div>
        </div>
        <div class="col-md-10 ml-auto col-xl-6 mr-auto">
          <p class="category">{{ this.$t('uaecn.page_main.title_double_title2') }}</p>
          <div style="border: solid 1px #db3832"></div>
          <div style="height: 20px;display: block;"></div>
          <div v-for="(item,index) in China" :key="index">
            <el-card shadow="hover">
              <div class="myElCard" :lang="language">
                <div class="head" :lang="language">{{ item.head }}</div>
                <div class="subtitle">{{ item.subtitle }}</div>
                <div style="display: flex;margin-top: 10px;">
                  <div><img :src="item.pic" class="myImg"/></div>
                  <div class="myFont" :lang="language">
                    {{ item.content }}
                  </div>
                </div>
              </div>
            </el-card>
            <div v-if="index != 2" style="margin-top: 20px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      language: 'zh',
      Dibai: [
        {
          head: this.$t('uaecn.page_main.support_a1_t'),
          subtitle: this.$t('uaecn.page_main.support_a1_st'),
          pic: 'img/product/card10.png',
          content: this.$t('uaecn.page_main.support_a1_c')
        },
        {
          head: this.$t('uaecn.page_main.support_a2_t'),
          subtitle: this.$t('uaecn.page_main.support_a2_st'),
          pic: 'img/product/card8.png',
          content: this.$t('uaecn.page_main.support_a2_c')
        },
        {
          head: this.$t('uaecn.page_main.support_a3_t'),
          subtitle: this.$t('uaecn.page_main.support_a3_st'),
          pic: 'img/product/card9.png',
          content: this.$t('uaecn.page_main.support_a3_c')
        }
      ],
      China: [
        {
          head: this.$t('uaecn.page_main.support_b1_t'),
          subtitle: this.$t('uaecn.page_main.support_b1_st'),
          pic: 'img/product/card7.png',
          content: this.$t('uaecn.page_main.support_b1_c')
        },
        {
          head: this.$t('uaecn.page_main.support_b2_t'),
          subtitle: this.$t('uaecn.page_main.support_b2_st'),
          pic: 'img/product/card11.png',
          content: this.$t('uaecn.page_main.support_b2_c')
        },
        {
          head: this.$t('uaecn.page_main.support_b3_t'),
          subtitle: this.$t('uaecn.page_main.support_b3_st'),
          pic: 'img/product/card12.png',
          content: this.$t('uaecn.page_main.support_b3_c')
        }
      ]
    };
  },
  created() {
    if (localStorage.getItem('change-language')) {
      this.language = localStorage.getItem('change-language')
    }
  },
};
</script>
<style lang="scss" scoped>

::v-deep .el-card__body {
  padding: 0px;
}

.myElCard[lang="zh"] {
  padding: 40px;
}

.myElCard[lang="en"] {
  padding: 40px;
  min-height: 325px;
  max-height: 325px;
}

.head[lang="zh"] {
  color: #2F5394;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}

.head[lang="en"] {
  color: #2F5394;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}

@media screen and (max-width: 768px) {
  .myElCard[lang="en"] {
    padding: 20px;
    max-height: 464px;
  }

  .head[lang="en"] {
    color: #2F5394;
    font-size: 15px;
    font-weight: bold;
    line-height: 28px;
  }
}

.subtitle {
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
}

.myImg {
  min-width: 125px;
  max-height: 125px;
}

.myFont[lang="zh"] {
  font-size: 14px;
  color: #333333;
  line-height: 22px;
  padding: 0 50px;
  max-height: 125px;
}

.myFont[lang="en"] {
  font-size: 14px;
  color: #333333;
  line-height: 22px;
  padding: 0 50px;
  max-height: 125px;
}

@media screen and (max-width: 768px) {
  .myFont[lang="zh"] {
    font-size: 12px;
    color: #333333;
    line-height: 22px;
    margin-left: 10px;
    padding: 0;
    max-height: 218px;
  }
  .myFont[lang="en"] {
    font-size: 12px;
    color: #333333;
    line-height: 22px;
    margin-left: 10px;
    padding: 0;
    max-height: 308px;
  }
}

.category {
  margin-top: 50px;
  color: #333333;
}
</style>
