import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Context from './pages/components/Context.vue';
//平台简介
import intro from './pages/components/intro.vue';
// 动态要闻
import news from './pages/components/news.vue';
// 动态要闻详情
import trendsNewsInfo from './pages/components/TrendsNewsInfo.vue';
// 中阿政务服务中心
import serviceGSC from './pages/components/serviceGSC.vue';
// 国际贸易服务中心
import serviceITSC from './pages/components/serviceITSC.vue';
// 国际投融资服务中心
import serviceIIFSC from './pages/components/serviceIIFSC.vue';
// 国际金融结算中心
import serviceIFSC from './pages/components/serviceIFSC.vue';
// 国际文化教育合作中心
import serviceICECC from './pages/components/serviceICECC.vue';
// 联系我们
import contactUs from './pages/components/ContactUs.vue';
import login from './pages/components/Login.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';

Vue.use(Router);

export default new Router({
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'index',
            components: {default: Index, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/context',
            name: 'context',
            components: {default: Context, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/intro',
            name: 'intro',
            components: {default: intro, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/news',
            name: 'news',
            components: {default: news, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/trendsNewsInfo',
            name: 'trendsNewsInfo',
            components: {default: trendsNewsInfo, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/serviceGSC',
            name: 'serviceGSC',
            components: {default: serviceGSC, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/serviceITSC',
            name: 'serviceITSC',
            components: {default: serviceITSC, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/serviceIIFSC',
            name: 'serviceIIFSC',
            components: {default: serviceIIFSC, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/serviceIFSC',
            name: 'serviceIFSC',
            components: {default: serviceIFSC, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/serviceICECC',
            name: 'serviceICECC',
            components: {default: serviceICECC, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/contactUs',
            name: 'contactUs',
            components: {default: contactUs, header: MainNavbar, footer: MainFooter},
            props: {
                header: {colorOnScroll: 400},
                footer: {backgroundColor: 'black'}
            }
        },
        {
            path: '/login',
            name: 'login',
            components: {default: login},
        }
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    }
});
