<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
          class="page-header-image"
          style="background-image: url('img/product/chanye-head-banner.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">

          <div class="title">{{ this.$t('uaecn.page_service_cul.page_title') }}</div>
          <div class="text-center">
            {{ this.$t('uaecn.page_service_cul.page_title_sub') }}
          </div>

        </div>
      </div>
    </div>

    <!--    tabs  begin-->
    <div class="section">
      <div class="container">
        <div class="row justify-content-center">
          <!-- Tab 1 -->
          <a class="col-md-auto col-12 tab-item" href="#/serviceGSC"
             style="width: 250px;height:55px;color: #666666;line-height: 41px; margin-left: 10px;margin-right: 10px;">
            {{ this.$t('uaecn.header.menu_sub2_pol') }}
          </a>
          <!-- Tab 2 -->
          <a class="col-md-auto col-12 tab-item" href="#/serviceITSC"
             style="width: 250px;height:55px;color: #666666;line-height: 41px; margin-left: 10px;margin-right: 10px;">
            {{ this.$t('uaecn.header.menu_sub2_tra') }}
          </a>
          <!-- Tab 3 -->
          <a class="col-md-auto col-12 tab-item" href="#/serviceIIFSC"
             style="width: 250px;height:55px;color: #666666;line-height: 41px;  margin-left: 10px;margin-right: 10px;">
            {{ this.$t('uaecn.header.menu_sub2_inv') }}
          </a>
          <!-- Tab 4 -->
          <a class="col-md-auto col-12 tab-item" href="#/serviceIFSC"
             style="width: 250px;height:55px;color: #666666;line-height: 41px;  margin-left: 10px;margin-right: 10px;">
            {{ this.$t('uaecn.header.menu_sub2_pay') }}
          </a>
          <!-- Tab 5 -->
          <a class="col-md-auto col-12 tab-item" href="#/serviceICECC"
             style="width: 250px;height:55px;color: #FFFFFF;line-height: 41px; margin-left: 10px;margin-right: 10px;background-color: #2f5394;">
            {{ this.$t('uaecn.header.menu_sub2_cul') }}
          </a>
        </div>
        <!-- Tab内容 -->
        <div class="tab-content">
          <div v-show="currentTab === 5">
            <div class="container my-5">
              <!-- 标题 -->
              <!--              <div class="row mb-4" style="padding: 50px;">-->
              <!--                <div class="col col-md-11" style="text-align: center;">-->
              <!--                  <h3>团队文化建设合作中心</h3>-->
              <!--                  <p>扎根中国、面向世界，构建国内唯一体化的文化建设高质量的合作交流平台，资源共享平台，创新方法平台，人才交流平台，产业发展平台。</p>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--     国际投融资服务中心   图文 开始   -->
              <div class="container">
                <div class="row">
                  <div class="col-md-12 ml-auto mr-auto">
                    <h3 style="text-align: center;">{{ this.$t('uaecn.page_service_cul.content_t1') }}</h3>
                    <p style="text-align: center;">{{ this.$t('uaecn.page_service_cul.content_t1_p1') }}</p>
                  </div>
                </div>
              </div>
              <!-- 服务模块 -->
              <div class="row justify-content-around">

                <!-- 模块一 -->
                <div class="col-12 col-md-6 ">
                  <div class="card" style="padding: 40px;">
                    <div class="col-12 col-md-12 mb-6 d-flex">
                      <div class="p-2" style="background-color: #2F5394;margin-right: 30px;">
                        <img src="img/product/xueshu-jiaoliu.png" class="img-fluid" alt="学术交流图标">
                      </div>
                      <div class="flex-grow-1 p-2 d-flex flex-column text-left">
                        <h5 style="font-weight:600;color:#2f5394;">{{ this.$t('uaecn.page_service_cul.card1_t') }}</h5>
                        <p>{{ this.$t('uaecn.page_service_cul.card1_c') }}</p>
                      </div>
                    </div>
                    <div class="card-body text-right">
<!--                      <a @click="openPlatform()" class="myA">{{ this.$t('uaecn.page_service_cul.button') }}</a>-->
                    </div>
                  </div>
                </div>


                <!-- 模块二 -->
                <!-- 模块一 -->
                <div class="col-12 col-md-6 ">
                  <div class="card" style="padding: 40px;">
                    <div class="col-12 col-md-12 mb-6 d-flex">
                      <div class="p-2" style="background-color: #2F5394;margin-right: 30px;">
                        <img src="img/product/wenhua-jiaoliu.png" class="img-fluid" alt="学术交流图标">
                      </div>
                      <div class="flex-grow-1 p-2 d-flex flex-column text-left">
                        <h5 style="font-weight:600;color:#2f5394;">{{ this.$t('uaecn.page_service_cul.card2_t') }}</h5>
                        <p>{{ this.$t('uaecn.page_service_cul.card2_c') }}</p>
                      </div>
                    </div>
                    <div class="card-body text-right">
<!--                      <a @click="openPlatform()" class="myA">{{ this.$t('uaecn.page_service_cul.button') }}</a>-->
                    </div>
                  </div>

                </div>

              </div>

              <!-- 服务模块 -->
              <div class="row justify-content-around">

                <!-- 模块一 -->
                <div class="col-12 col-md-6 ">
                  <div class="card" style="padding: 40px;">
                    <div class="col-12 col-md-12 mb-6 d-flex">
                      <div class="p-2" style="background-color: #2F5394;margin-right: 30px;">
                        <img src="img/product/zhengce-yanjiu.png" class="img-fluid" alt="学术交流图标">
                      </div>
                      <div class="flex-grow-1 p-2 d-flex flex-column text-left">
                        <h5 style="font-weight:600;color:#2f5394;">{{ this.$t('uaecn.page_service_cul.card3_t') }}</h5>
                        <p>{{ this.$t('uaecn.page_service_cul.card3_c') }}</p>
                      </div>
                    </div>
                    <div class="card-body text-right">
<!--                      <a @click="openPlatform()" class="myA">{{ this.$t('uaecn.page_service_cul.button') }}</a>-->
                    </div>
                  </div>
                </div>


                <!-- 模块一 -->
                <div class="col-12 col-md-6 ">
                  <div class="card" style="padding: 40px;">
                    <div class="col-12 col-md-12 mb-6 d-flex">
                      <div class="p-2" style="background-color: #2F5394;margin-right: 30px;">
                        <img src="img/product/rencai-peixun.png" class="img-fluid" alt="学术交流图标">
                      </div>
                      <div class="flex-grow-1 p-2 d-flex flex-column text-left">
                        <h5 style="font-weight:600;color:#2f5394;">{{ this.$t('uaecn.page_service_cul.card4_t') }}</h5>
                        <p>{{ this.$t('uaecn.page_service_cul.card4_c') }}</p>
                      </div>
                    </div>
                    <div class="card-body text-right">
<!--                      <a @click="openPlatform()" class="myA">{{ this.$t('uaecn.page_service_cul.button') }}</a>-->
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--    tabs  end-->


  </div>
</template>
<script>
import {Button, FormGroupInput} from '@/components';
import ContextBanner from './ContextBanner.vue';
import ContextImageText from './ContextImageText.vue';
import ContextTextImage from './ContextTextImage.vue';
import config from '@/config/index.js';

// 获取配置文件中的 URL
const platformUrl = config.platformUrl;

export default {
  name: 'context',
  bodyClass: 'landing-page',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ContextBanner,
    // eslint-disable-next-line vue/no-unused-components
    ContextImageText,
    // eslint-disable-next-line vue/no-unused-components
    ContextTextImage,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      currentTab: 5,
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    openPlatform() {
      if (window.innerWidth <= 760) {
        this.$router.push('/login')
      }else {
        // 使用 window.open 打开新窗口
        window.open(platformUrl, '_blank');
      }
    }
  }
};
</script>
<style scoped>
.card-body {
  padding: 0px !important;
}

.card {
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2) !important;
}

.myA {
  color: #2f5394;
}

.myA:hover {
  color: #2f5394 !important;
  background-color: #FFFFFF !important;
}

.img-fluid {
  width: 150px;
  /*height:150px;*/
}

.title {
  color: #FFFFFF;
  text-align: center;
  font-size: 30px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.text-center {
  color: #FFFFFF;
  font-size: 26px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

p {
  font-size: 16px !important;
  font-weight: 400;
}

.section {
  padding: 20px 0 !important;
}

.container-a-class {
  text-decoration: none;
  color: inherit;
  cursor: default;
}

/* 鼠标悬浮时显示可点击样式 */
a:hover {
  color: #FFFFFF !important;
  text-decoration: none !important;
  cursor: pointer;
}


.tab-item {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 1px;
  padding: 5px;
  cursor: pointer;
  height: 40px;
  color: #CCCCCC;
}

.tab-content div {
  border-radius: 15px;
  margin-top: 5px;
  /*padding: 5px;*/
}

@media (max-width: 767.98px) {
  .d-flex {
    flex-direction: column;
    align-items: center;
  }

  .p-2 {
    padding: 0.5rem !important;
  }
}

@media (min-width: 768px) {
  .row.justify-content-center {
    flex-wrap: nowrap;
  }

  .d-flex {
    align-items: flex-start;
  }

  .p-2 {
    padding: 0.5rem !important;
  }
}

a:hover {
  color: #FFFFFF !important;
  text-decoration: none !important;
  background-color: #2f5394;
  cursor: pointer;
}
</style>

