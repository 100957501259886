// 英文
exports.default = {
  'Language': 'English',
  uaecn: {
    header: {
      menu_main: 'Home',
      menu_intro: 'Overview',
      menu_news: 'News',
      menu_service: 'Services',
      menu_contact: 'Contact Us',
      menu_back: 'Official Website',

      menu_sub_pol: 'China-UAE Government Services Center',
      menu_sub_tra: 'International Trade Services Center',
      menu_sub_inv: 'International Investment and Financing Services Center',
      menu_sub_pay: 'International Financial Settlement Center',
      menu_sub_cul: 'International Cultural and Educational Cooperation Center',

      menu_sub2_pol: 'Government Services',
      menu_sub2_tra: 'Trade Services',
      menu_sub2_inv: 'Investment and Financing',
      menu_sub2_pay: 'Financial Settlement',
      menu_sub2_cul: 'Cultural and Educational',

      button_reg: 'Sign in',
      button_login: 'Log in',
      published_date: 'Published Date：',
      source: 'Source：'
    },
    footer: {
      title: 'CHINA-UAE ECONOMIC AND TRADE COMPREHENSIVE SERVICE PLATFORM',
      address: 'Address：No.703A, Floor 7 , Fuer Building,No.9 East Third Ring Road, Chaoyang District, Beijing',
      email: 'E-mail：service@dixchain.com',
      ICP: '京ICP备20005006号-3',
      company: 'DIXchain'
    },
    page_main: {
      bar_pol: 'GOVERNMENT SERVICE',
      bar_tra: 'TRADE SERVICE',
      bar_inv: 'INVESTMENT & FINANCING',
      bar_pay: 'FINANCIAL SETTLEMENT',
      bar_cul: 'CULTURAL & EDUCATION',

      title_service: 'INDUSTRY EMPOWERS BY PLATFORM ECOSYSTEM',
      title_service_sub: 'Providing comprehensive, multi-level, high-quality services',
      title_news: 'NEWS',
      title_news_sub: 'LATEST CHINA-UAE NEWS',
      title_support: 'China-UAE Cross-Border Comprehensive Services',
      title_support_sub: 'Effectively provide services to cross-border enterprises and create a leading service model',
      title_double_title1: 'UAE-DUBAI',
      title_double_title2: 'CHINA-QINGDAO',

      card_pol_t: 'China-UAE Government Services Center',
      card_pol_c: 'Assist entrepreneurs from China and the United Arab Emirates in achieving cross-border business expansion and realize "one-click dual-park registration."',
      card_tra_t: 'International Trade Services Center',
      card_tra_c: 'Building on the foundation of economic and trade cooperation among China and UAE, expanding China-UAE trade cooperation, establishing an international trade development platform, and using digitization to facilitate international trade services.',
      card_inv_t: 'International Investment and Financing Services Center',
      card_inv_c: 'Gathering financial institutions and related service industries, utilizing trade big data, to comprehensively enhance the facilitation of international capital investment, international trade financing, insurance, and other financial services.',
      card_pay_t: 'International Financial Settlement Center',
      card_pay_c: 'Gathering economic and financial service needs of China and UAE, to provide one-stop international financial settlement services for China-UAE trade enterprise.',
      card_cul_t: 'International Cultural and Educational Cooperation Center',
      card_cul_c: 'Based in China, bridging domestic and international connections, and Constructing a cooperative exchange platform, resource sharing platform, innovation demonstration platform, talent exchange platform, and industrial development platform in the integrated field of culture and education.',

      service_icon01: 'Establishing China-UAE Multinational Company',
      service_icon02: 'Establishing China-UAE Bank Account',
      service_icon03: 'Trade Financing and IPO Services',
      service_icon04: 'China-UAE Trade Investment Service',
      service_icon05: 'China-UAE Cross-border Procurement',
      service_icon06: 'China-UAE One-stop Customs Clearance',
      service_icon07: 'China-UAE Logistics',
      service_icon08: 'China-UAE Visa Application',

      support_a1_t: 'Dubai NASDAQ IPO Platform',
      support_a1_st: 'Overseas Listing',
      support_a1_c: 'Assisting businesses in steering the direction of capital market development, achieving risk-free, low-cost IPOs, and gaining access to international capital. This supports companies in their digital transformation, empowers technological innovation, and providing ample capital environment that would enhance cooperation real economy.',

      support_a2_t: 'Middle East Cross-Border E-commerce Comprehensive Experimental Zone',
      support_a2_st: 'Providing Client with One-stop Cross-border E-commerce Services',
      support_a2_c: '"Cross-Border E-commerce Comprehensive Experimental Zone" launched in the Dubai Silicon Oasis Free Zone (DS0). It is located in (DSO) and offers customers a range of high-quality services, including product showcasing, transactions, overseas warehousing, supply chain financing, logistics, and more. It aims to provide integrated services connecting customers both domestically and internationally.',

      support_a3_t: 'Global Digital Industry Investment and Innovation Platform & Investment Summit',
      support_a3_st: 'Annual Global Investment Summit',
      support_a3_c: 'Every year, it gathers thousands of companies, building a bridge between capital and projects, creating new opportunities for industrial development, setting the benchmark in the global investment field, and becoming a key driving force in leading industry transformation.',

      support_b1_t: 'Qingdao-UAE Trade and Economic Cooperation Demonstration Zone',
      support_b1_st: 'UAE Trade and Economic Cooperation Demonstration Zone',
      support_b1_c: '“China-UAE Trade and Economic Cooperation Industrial Demonstration Zone" located in Qingdao, adheres to the principles of mutual respect, fairness, justice, and mutual benefit, with a focus on promoting economic and trade relations. It aims to stimulate regional economic prosperity, provide a favorable investment environment, and create collaborative opportunities for enterprises.',

      support_b2_t: 'Qingdao · Government Resource Support',
      support_b2_st: 'Qingdao’s Government Support',
      support_b2_c: 'The Chinese government has provided a series of support measures, including simplifying approval processes, offering tax incentives, and strengthening policy support. The support from the Chinese government has provided more convenient and efficient government services for businesses in China and the UAE.',

      support_b3_t: 'China · Financial Institutions',
      support_b3_st: 'Various Financial Institutions in China',
      support_b3_c: 'Domestic banks and financial institutions in China also provide support to the China-UAE Comprehensive Service Platform. They offer financial services such as investment, financing, and listing, including innovative financial products, streamlined service processes, and enhanced risk control. This has provided more diversified and convenient financial services to businesses in China and the UAE.',

      button_reg1: 'Read More',
      button_reg2: 'Register Now',
      button_reg3: 'Register Now',
      button_reg4: 'Read More',
    },
    page_intro: {
      page_title: 'CHINA-UAE ECONOMIC AND TRADE COMPREHENSIVE SERVICE PLATFORM',
      page_title_sub: 'Centered in China and UAE, Support the Global Community',
      content_t1: 'Background Introduction',
      content_t1_p1: 'In recent years, the cooperation between China and the United Arab Emirates (UAE) has continued to deepen. China has consistently been the UAE\'s largest trading partner for several years. The UAE, in turn, is China\'s second-largest trading partner in the Arab region, its largest export market, and the top destination for Chinese investments. ',
      content_t1_p2: 'China and the United Arab Emirates (UAE) have seen a continuous growth in their trade cooperation. According to official statistics From China: In recent years, the total trade volume between China and the UAE has maintained a rapid and sustained increase. In 2022, the total trade volume between China and the UAE reached $99.27 billion, representing a year-on-year increase of 37.4%. Of this, Chinese exports amounted to $53.86 billion, a year-on-year increase of 23.3%, while imports from the UAE amounted to $45.41 billion, showing a significant year-on-year increase of 58.9%. From January to November 2023, the trade volume between China and the UAE amounted to $47.01 billion, with a year-on-year increase of 2.4%. This data highlights the strong and growing trade relationship between the two countries.',
      content_t1_p3: 'Given the deepening cooperation between China and the United Arab Emirates (UAE) and the continuous growth in trade cooperation, it is imperative to establish a China-UAE International Cooperation and Innovation Comprehensive Service Platform. Utilizing digital means, this platform can facilitate collaboration between the two countries in various areas such as culture, government affairs, trade, finance, and more. This initiative is seen as a necessity to further enhance bilateral cooperation and take advantage of the opportunities in this evolving partnership.',
      content_t1_p4: 'Based on the background described above, the creation of a China-UAE Comprehensive Economic and Trade Service Platform is essential. Leveraging technological means, this platform aims to provide services to China and UAE enterprises, including governmental services, international trade, international investment and financing, international financial settlements, and international cultural and educational exchange cooperation. This platform will help China\'s companies expand into the UAE and Middle East markets and assist UAE companies in entering the China market, ultimately strengthening international cooperation between the two nations.',
      content_t2: 'Core Values',
      content_t2_p1: 'The China-UAE Comprehensive Economic and Trade Service Platform is dedicated to promoting economic and trade relations between both countries, as well as between China and the Middle East region, with the goal of fostering regional economic prosperity. The platform will adhere to principles of mutual respect, fairness, equity, and mutual benefit to facilitate multi-dimensional, multi-sectoral cooperation between the two nations, particularly in the economic domain. It aims to strengthen ties, reduce market barriers, expand trade and investment, harness complementary advantages, and achieve mutual development. Additionally, it seeks to contribute to the establishment of an equitable and mutually beneficial international trade environment.'
    },
    page_news: {
      page_title: 'LATEST NEWS',
      page_title_sub1: 'Announcements',
      page_title_sub2: 'News',
      page_title_sub3: 'Policy',
    },
    page_service_pol: {
      page_title: 'SERVICES',
      page_title_sub: 'China-UAE Government Services Center',
      content_t1: 'China-UAE Government Services Center',
      content_t1_p1: 'Assist entrepreneurs from China and the United Arab Emirates in achieving cross-border business expansion and realize "one-click dual-park registration."',
      card1_t: 'China-UAE Policy Consultation',
      card1_c: 'Intelligent consultation services for relevant policies in China and the UAE, including government, trade, finance, customs, and industrial park-related policies.',
      card2_t: 'China-UAE Tax Services',
      card2_c: 'China-UAE Intelligent Tax Advisory Services, helping businesses efficiently access the best tax planning policies and stay informed about the latest updates.',
      card3_t: 'Express Company Registration',
      card3_c: 'Fast registration for Chinese and UAE enterprises, online processing, one-click dual-park, and one-stop services for convenience and efficiency.',
      card4_t: 'Visa Application Service',
      card4_c: 'Visa application services for China and the UAE visas, with online applications for tourist and business visas, fast approval, and one-stop services.',
      button: 'Register'
    },
    page_service_tra: {
      page_title: 'SERVICES',
      page_title_sub: 'International Trade Services Center',
      content_t1: 'International Trade Services Center',
      content_t1_p1: 'Elevating convenient service at every step of cross-border trade.',
      button: 'Register'
    },
    page_service_inv: {
      page_title: 'SERVICES',
      page_title_sub: 'International Investment and Financing Services Center',
      content_t1: 'International Investment and Financing Services Center',
      content_t1_p1: 'Dedicated to providing support and cooperation opportunities for enterprises from China and UAE.',
      card1_t: 'China-UAE Investment Environment Assessment',
      card1_c: 'Providing market analysis and forecasts of both countries, assisting businesses in formulating more effective market entry strategies, analyzing the investment environments, and offering wise decision-making support to investors.',
      card2_t: 'Promoting China-UAE Investment Cooperation',
      card2_c: 'By establishing investment cooperation channels, we aim to facilitate collaboration and communication between Chinese and UAE enterprises, fostering negotiations and cooperation on investment projects between the two parties.',
      card3_t: 'Offering Trade Financing Services',
      card3_c: 'Utilizing the financial institutions resources in China and UAE, we expand the financing channels for businesses, offering a convenient pathway for effective connections.',
      card4_t: 'Promoting China-UAE Policy Collaboration',
      card4_c: 'Collaborating with relevant government agencies from both countries, we offer policy advisory services in the fields of investment and financing to businesses from both nations, providing a favorable policy environment to support these enterprises.',
      button: 'Register'
    },
    page_service_pay: {
      page_title: 'SERVICES',
      page_title_sub: 'International Financial Settlement Center',
      content_t1: 'International Financial Settlement Center',
      content_t1_p1: 'Achieve a one-stop international trade payment and settlement solution that encompasses opening cross-border accounts under the same identity, foreign exchange settlement, and onshore withdrawals.',
      content_t2: 'Foreign Currency Settlement',
      content_t2_p1: 'The People\'s Bank of China and the Central Bank of the United Arab Emirates have renewed a currency swap agreement in Hong Kong for a scale of 35 billion RMB / 18 billion Dirhams (4.9 billion USD), extending the China-UAE currency swap agreement for another five years. The agreement, renewed by both parties, will promote China-UAE trade, strengthen trade cooperation between the two countries, and facilitate the provision of liquidity to financial markets in local currencies for cross-border financial and trade transactions in a "more efficient and effective manner".',
      content_t3: 'Currency Swap',
      content_t3_p1: 'Out of nationwide free trade zone, the first UAE Dirham settlement business has been established in Qingdao Cross-Border Trade Zone. This represents a new breakthrough in cross-border settlement of small currencies and enhances the confidence of enterprises in expanding new businesses.',
      content_t4: 'Policy Research',
      content_t4_p1: 'China and UAE have signed a memorandum of understanding on strengthening digital currency cooperation. As trade deepens between the two countries, it will facilitate the direct use of digital currencies in international trade, thus promoting the improvement of the digital currency settlement system.',

      card1_t: 'Multi-Currency',
      card1_c: 'Supporting over 10 major currencies, autonomous exchange rate locking, mitigate exchange rate risks, optimal rate routing to reduce currency conversion costs.',
      card2_t: 'Free Registration (Limited time only)',
      card2_c: 'Free international payment account, Instant activation. Register once and get direct free access to three overseas accounts.',
      card3_t: 'SAS',
      card3_c: 'Supports purchase agreement separate accounting system (SAS) and supplier splitting. Simply provide a purchase agreement to withdraw funds directly to the supplier\'s corporate account and Company\'s Director.',
      card4_t: 'Global Payment',
      card4_c: 'Supports payments for export businesses in major countries worldwide, with some exceptions in certain countries and regions.',
      card5_t: 'Multiple Method',
      card5_c: 'Multiple payment methods (telegraphic transfer/TT /CHATS) with fast processing times. Submit PI (Proforma Invoice) and logistics information, get approval, and withdraw funds in real-time.',
      card6_t: 'Safety & Compliance',
      card6_c: 'Cross-border payments, secure and compliance. Mature risk management mechanisms and anti-money laundering management.',

      button: 'Read More'
    },
    page_service_cul: {
      page_title: 'SERVICES',
      page_title_sub: 'International Cultural and Educational Cooperation Center',
      content_t1: 'International Cultural and Educational Cooperation Center',
      content_t1_p1: 'Based in China, bridging domestic and international connections, Constructing a cooperative exchange platform, resource sharing platform, innovation demonstration platform, talent exchange platform, and industrial development platform in the integrated field of culture and education.',
      card1_t: 'Academic Exchange',
      card1_c: 'Providing a facilitative platform for China and UAE research collaboration, organizing academic visits, and preparing scholar exchange programs to promote academic exchange and cooperation between the two countries.',
      card2_t: 'Cultural Exchange',
      card2_c: 'Holding cultural events, art exhibitions, performances, and seminars to enhance mutual cultural understanding between the two countries through activities related to art, music, and traditional culture.',
      card3_t: 'Policy Research',
      card3_c: 'Conducting in-depth research on trade policies in both China and UAE, offering rational recommendations based on business needs to assist companies in adapting to the trade environments of both countries.',
      card4_t: 'Talent Training',
      card4_c: 'Providing training on market regulations, finance, and legal aspects for business professionals in the cooperation field, ensuring that companies can expand their operations legally and in compliance with the regulations of the region.',
      button: 'Read More'
    },
    page_contact: {
      page_title: 'CONTACT US',
      page_title_sub: 'Centered in China and UAE, Support the Global Community',
      title: 'CHINA-UAE ECONOMIC AND TRADE COMPREHENSIVE SERVICE PLATFORM',
      address0: 'Address：',
      address1: 'China · No.703A, Floor 7 , Fuer Building,No.9 East Third Ring Road, Chaoyang District, Beijing',
      address2: 'Dubai · 49GJ+3FG - Dubai Silicon Oasis - Dubai',
      email: 'Email ：service@dixchain.com',
      wt_t: 'Business Hours',
      wt_m: 'Monday-Friday: 9:00~18:00',
      wt_e: 'Get in Touch with Us'
    },
    phone_title: {
      title: 'Please use PC to access',
      content:　'Full functionality is available',
    }

  }
}
