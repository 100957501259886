<template>
  <el-dropdown @command="handle">
    <span class="el-dropdown-link" style="font-size: 16px">
      {{ $t('Language') }}<i class="el-icon-caret-bottom el-icon--right"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="(item, index) of list" :key="index" :command="item.key">{{ item.name }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'change-language',
  data() {
    return {
      list: [
        {key: 'en', name: 'English'}, // 英语
        {key: 'zh', name: '简体中文'} // 中文
      ]
    }
  },
  methods: {
    handle(value) {
      this.$i18n.locale = value
      localStorage.setItem('change-language', value)
      window.location.reload();
    }
  }
}
</script>
<style scoped lang="scss">
</style>
