/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
// 引用国际化英文
import i18n from '@/components/i18n'
import axios from 'axios';
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

Vue.use(NowUiKit);

import tool from "@/components/utils/tool";
Vue.use(tool);

Vue.$message = Element.Message
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');


if (process.env.NODE_ENV === 'production') {
  const hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?a5069d3502e36ff0cf3f7314c1b57890";
  const s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
}



let script = document.createElement('script');
script.type = 'text/javascript';
script.src = 'https://static.chatbotkit.com/integrations/widget/v2.js';
script.id ="chatbotkit-widget";
script.setAttribute("data-widget","clrribxgo0iqr1c0u7g2yv2bq");
document.body.appendChild(script);
