<template>
  <div>
    <div style="background: #f6f6f6;">
      <div class="container">
        <div style="padding: 30px 0 30px 40px;">
          <div style="font-size: 20px; font-weight: bold;color: #333333">
            {{ this.$t('uaecn.page_main.title_service') }}
          </div>
          <div style="font-size: 14px; color: #666666">
            {{ this.$t('uaecn.page_main.title_service_sub') }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-lg-4" style="padding: 0 0">
            <div class="col-sm-12 col-lg-12">
              <el-card shadow="hover" class="custom-card" style="height: 620px;">
                <div class="card-content" style="display: flex;justify-content: center;">
                  <img src="img/product/card1.png" class="card-image" style="width: 220px;height: 220px;"/>
                </div>
                <div>
                  <div class="head" style="margin-top: 8px;">{{ this.$t('uaecn.page_main.card_pol_t') }}</div>
                  <div class="content2" :lang="language">
                    {{ this.$t('uaecn.page_main.card_pol_c') }}
                  </div>
                  <el-button @click="openPlatform()" class="myButton" :lang="language">
                    {{ this.$t('uaecn.page_main.button_reg1') }}
                  </el-button>
                </div>
              </el-card>
            </div>
          </div>
          <div class="col-sm-6 col-lg-8" style="display: flex;flex-wrap: wrap;padding: 0 0">
            <div
                class="col-sm-6 col-lg-6"
                v-for="(item, index) in cardInfo"
                :key="index"
            >
              <el-card shadow="hover" class="custom-card" :lang="language">
                <div>
                  <div class="head" style="height: 60px;">{{ item.head }}</div>
                  <div class="content" :lang="language">
                    {{ item.content }}
                  </div>
                </div>
                <div class="cardfoot">
                  <el-button @click="openPlatform()">{{ getTranslatedText('uaecn.page_main.button_reg1') }}</el-button>
                  <div class="card-content myImg">
                    <img :src="item.pic" class="card-image"/>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
        </div>
        <div style="text-align: center;">
          <el-button @click="openPlatform()" style="padding: 20px 40px;border-radius: 30px;font-size: 16px;">
            {{ this.$t('uaecn.page_main.button_reg2') }}
          </el-button>
        </div>
        <div class="space-50"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {Button} from "@/components";
import config from '@/config/index.js';

// 获取配置文件中的 URL
const platformUrl = config.platformUrl;

export default {
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      language: 'zh',
      cardInfo: [
        {
          head: this.$t('uaecn.page_main.card_tra_t'),
          content: this.$t('uaecn.page_main.card_tra_c'),
          pic: "img/product/card2.png",
        },
        {
          head: this.$t('uaecn.page_main.card_inv_t'),
          content: this.$t('uaecn.page_main.card_inv_c'),
          pic: "img/product/card3.png",
        },
        {
          head: this.$t('uaecn.page_main.card_pay_t'),
          content: this.$t('uaecn.page_main.card_pay_c'),
          pic: "img/product/card4.png",
        },
        {
          head: this.$t('uaecn.page_main.card_cul_t'),
          content: this.$t('uaecn.page_main.card_cul_c'),
          pic: "img/product/card6.png",
        },
      ],
    };
  },
  created() {
    let value = localStorage.getItem('change-language');
    if (value) {
      this.language = value
    }
  },
  methods: {
    getTranslatedText(key) {
      return this.$t(key);
    },
    openPlatform() {
      if (window.innerWidth <= 760) {
        this.$router.push('/login')
      }else {
        // 使用 window.open 打开新窗口
        window.open(platformUrl, '_blank');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-card {
  margin-bottom: 20px;
}

::v-deep .el-card__body {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.head {
  color: #2f5394;
  font-size: 20px;
  font-weight: bold;
}

.content[lang="zh"] {
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
  height: 80px;
}

.content[lang="en"] {
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
  height: 110px;
}

.custom-card[lang="en"] {
  height: 300px;
}

@media screen and (max-width: 768px) {
  .content[lang="zh"] {
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px;
    height: 80px;
  }

  .content[lang="en"] {
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px;
    height: 110px;
  }

  .custom-card[lang="zh"] {
    height: 300px;
  }

  .custom-card[lang="en"] {
    height: 350px;
  }
}

.content2[lang="zh"] {
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
  height: 230px;
}

.content2[lang="en"] {
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
  height: 230px;
}

@media screen and (max-width: 768px) {
  .content2[lang="en"] {
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px;
    height: 175px;
  }
}

.myImg {
  max-width: 20%;
}

.cardfoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardHead {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-button {
  background: linear-gradient(to bottom, #2F5394, #4488F5);
  color: #ffffff;
}

.card-image {
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out; /* 过渡效果 */
}

.custom-card[lang="zh"] {
  height: 300px;
}

@media screen and (max-width: 768px) {
  .content[lang="en"] {
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px;
    height: 160px;
  }
}

.custom-card:hover .card-image {
  opacity: 1; /* 悬停时改变图片透明度为 1 */
}

.card-content {
  position: relative;
  transition: transform 0.5s ease-in-out;
}

.custom-card:hover .card-content {
  transform: scale(1.1); /* 鼠标悬停时放大 1.1 倍 */
}

.myButton[lang="zh"] {
  margin-top: -9px;
}

.myButton[lang="en"] {
  margin-top: 21px;
}
</style>
